import facebookLogo from "./assets/images/Facebook_Logo.png";
import zaloLogo from "./assets/images/zaloLogo.png";
import logoInternet2 from "./assets/images/tuvanvien.jpg";
import apple from "./assets/images/apple.jpg";
import samsung from "./assets/images/samsung.png";
import gioHang from "./assets/images/giohang2.jpg";
import xiaomi from "./assets/images/xiaomi.jpeg";
import vivo from "./assets/images/vivo.jpg";
import oppo from "./assets/images/oppo.jpg";
import realme from "./assets/images/realme.jpg";
import huawei from "./assets/images/huawei.jpg";
import "./App.scss";
import { useEffect, useState } from "react";
import { getTTShop } from "./ApiRequest";
import Loading from "./Loading";
import QRCode from "react-qr-code";
import logoInternet from "./assets/images/logoInternet.jpg";
function App() {
    const idShop = "6573ed198236b1e3500d1904";
    const [tuVanVaThongTin, settuVanVaThongTin] = useState(0);
    const [dataQrCode, setdataQrCode] = useState("");
    const [loading, setloading] = useState(1);
    // Thong Tin Shop
    const [ttShop, setttShop] = useState();
    useEffect(() => {
        const fetchPublicDictrict = async () => {
            const response = await getTTShop(idShop, setloading);
            if (response) {
                setttShop(response?.data.shop);
            }
        };
        idShop && fetchPublicDictrict();
    }, [idShop]);
    // console.log("ttShop", ttShop);
    // Thong Tin Shop
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const download = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            // name image
            downloadLink.download = `${dataQrCode}`;
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    // San Pham Dan
    const [spa, setspa] = useState();
    const [spb, setspb] = useState();
    const [sanPhamDan, setsanPhamDan] = useState([]);
    useEffect(() => {
        const limit = 2;
        const skip = 0;
        const fetchData = async () => {
            try {
                const res = await fetch(
                    `https://fabysa.com/v1/shop/san-pham-dan-huyen2/?huyen=${idShop}&skip=${skip}&limit=${limit}`
                );
                const data2 = await res.json();
                setsanPhamDan(data2?.allSanpham);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [idShop]);
    useEffect(() => {
        if (sanPhamDan && sanPhamDan?.length !== 0) {
            setspa(sanPhamDan[0]?._id);
            setspb(sanPhamDan[1]?._id);
        }
    }, [sanPhamDan]);
    // San Pham Dan
    // muaHang
    const handleMuaHang = () => {
        window.location.href = `https://fabysa.com/shop/${idShop}`;
    };
    // muaHang

    return (
        <>
            <div className="all">
                {(loading === 0 || loading !== 1) && (
                    <div className="App">
                        <div>
                            {/* Banner */}
                            <div className="ttShop">
                                <img
                                    src={ttShop?.Banner}
                                    className="banner-container"
                                />

                                <a href={`/`}>
                                    <div className="tenCuaHang">
                                        {ttShop?.TenShop}
                                    </div>

                                    <div className="internet-website">
                                        <img
                                            src={logoInternet}
                                            className="internet"
                                        />
                                        <div className="website">
                                            Https://
                                            {ttShop?.website}
                                        </div>
                                        <img
                                            src={logoInternet2}
                                            className="internet"
                                        />
                                    </div>
                                </a>
                            </div>
                            {/* Banner */}

                            {/* muaHang */}
                            <div
                                onClick={() => handleMuaHang()}
                                className="muaHang-container"
                            >
                                <img className="gioHang" src={gioHang} />
                                <div className="muaHang">Mua Hàng</div>
                            </div>
                            {/* muaHang */}

                            {/* Fabysa */}
                            <div className="tuVan-gioiThieu">
                                <div
                                    className="tuVan"
                                    onClick={() => settuVanVaThongTin(2)}
                                >
                                    <div>Fabysa</div>
                                </div>
                                <div
                                    className="gioiThieu"
                                    onClick={() => settuVanVaThongTin(1)}
                                >
                                    <div>Liên Hệ</div>
                                </div>
                            </div>
                            {/* Fabysa */}
                            {tuVanVaThongTin === 1 && (
                                <div className="gioiThieuChiTiet">
                                    <button
                                        className="closeGioiThieu"
                                        onClick={() => settuVanVaThongTin(0)}
                                    >
                                        Close
                                    </button>
                                    <div className="tenCuaHang2">
                                        {ttShop?.TenShop}
                                    </div>
                                    <div className="slogan">
                                        {ttShop?.sloganShop}
                                    </div>

                                    <div className="qrcode">
                                        <div
                                            onClick={download}
                                            style={{
                                                height: "auto",
                                                margin: "0 auto",
                                                maxWidth: 150,
                                                width: "100%",
                                            }}
                                        >
                                            <QRCode
                                                size={256}
                                                style={{
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                }}
                                                value={`https://${ttShop?.website}`}
                                                viewBox={`0 0 256 256`}
                                                id="QRCode"
                                            />
                                        </div>
                                    </div>

                                    <div className="dc">
                                        - Địa chỉ:&nbsp;
                                        {ttShop?.dcShop} <br />- Số điện
                                        thoại:&nbsp;
                                        {ttShop?.sdtShop} <br />- Quý khách cần
                                        hỗ trợ hoặc tư vấn xin vui lòng liên hệ
                                        trực tiếp 24/7 qua Zalo, Facebook!
                                    </div>

                                    <div className="mxh">
                                        <div className="zalo">
                                            <a
                                                href={`https://zalo.me/${ttShop?.linkZalo}`}
                                                target="_blank"
                                            >
                                                <img
                                                    src={zaloLogo}
                                                    className="zalo"
                                                />
                                            </a>
                                        </div>
                                        <div className="facebook">
                                            <a
                                                href={ttShop?.linkFacebook}
                                                target="_blank"
                                            >
                                                <img
                                                    src={facebookLogo}
                                                    className="facebook"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="camOn">
                                        Xin chân thành cảm ơn!
                                    </div>
                                    <a
                                        href={`https://fabysa.com/shop/dang-nhap/${idShop}`}
                                    >
                                        <div className="like">Thích</div>
                                    </a>
                                </div>
                            )}
                            {tuVanVaThongTin === 2 && (
                                <div className="tuVanChiTiet">
                                    <button
                                        className="closeGioiThieu"
                                        onClick={() => settuVanVaThongTin(0)}
                                    >
                                        Close
                                    </button>

                                    <div className="fabysa">
                                        Trung Tâm Thương Mại Fabysa
                                    </div>
                                    <div className="gioiThieuFabysa">
                                        - Giới thiệu danh sách Shop Uy Tín!
                                        <br /> - Sản phẩm đa dạng ngành hàng!{" "}
                                        <br />- Giá cả ưu đãi và rất nhiều
                                        khuyến mại!
                                    </div>
                                    <a
                                        href={`https://fabysa.com/fabysa/${spa}/${spb}`}
                                    >
                                        <div className="sanSale">
                                            Săn Sale Ngay
                                        </div>
                                    </a>
                                </div>
                            )}
                        </div>

                        <div className="tieuDe">
                            Các Hãng SmartPhone Nổi Tiếng
                        </div>
                        <div className="hoa">
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">1. Apple</div>
                                <img className="anhLoaiHoa" src={apple} />
                                <div className="thongTinLoaiHoa">
                                    Apple Inc. là một Tập đoàn công nghệ đa quốc
                                    gia của Mỹ có trụ sở chính tại Cupertino,
                                    California, chuyên Thiết kế, phát triển và
                                    bán thiết bị điện tử tiêu dùng, phần mềm máy
                                    tính và các dịch vụ trực tuyến. <br />
                                    Apple Computer Company được thành lập vào
                                    ngày 1 tháng 4 năm 1976, bởi Steve Jobs,
                                    Steve Wozniak và Ronald Wayne với tư cách
                                    hợp tác kinh doanh. <br />
                                    Doanh thu: 274,515 tỉ USD (2020)
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">2. Sam Sung</div>
                                <img className="anhLoaiHoa" src={samsung} />
                                <div className="thongTinLoaiHoa">
                                    Tập đoàn Samsung hay Samsung là một tập đoàn
                                    đa quốc gia của Hàn Quốc có trụ sở chính đặt
                                    tại Samsung Town, Seocho, Seoul. Tập đoàn sở
                                    hữu rất nhiều công ty con, chuỗi hệ thống
                                    bán hàng cùng các văn phòng đại diện trên
                                    toàn cầu hoạt động dưới tên thương hiệu mẹ.{" "}
                                    <br />
                                    Ngày thành lập: 1 tháng 3, 1938, Daegu, Hàn
                                    Quốc
                                    <br />
                                    Nhà sáng lập: Lee Byoung Chul <br /> Trụ sở:
                                    Suwon, Hàn Quốc <br />
                                    Doanh thu: 211,2 tỷ USD (2017)
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">3. Xiaomi</div>
                                <img className="anhLoaiHoa" src={xiaomi} />
                                <div className="thongTinLoaiHoa">
                                    Xiaomi Inc là một Tập đoàn sản xuất hàng
                                    điện tử Trung Quốc có trụ sở tại Thâm Quyến.
                                    <br />
                                    Xiaomi là nhà sản xuất điện thoại thông minh
                                    lớn thứ 2 thế giới; trong quí 3 năm 2021,
                                    Xiaomi đã chiếm gần 17% thị trường điện
                                    thoại thông minh thế giới.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">4. Vivo</div>
                                <img className="anhLoaiHoa" src={vivo} />
                                <div className="thongTinLoaiHoa">
                                    Vivo là một công ty Trung Quốc chuyên thiết
                                    kế, phát triển và sản xuất điện thoại thông
                                    minh Android, phụ kiện điện thoại thông
                                    minh, phần mềm và dịch vụ trực tuyến. <br />
                                    Vivo được thành lập năm 2009 tại Đông Hoản,
                                    Quảng Đông, và là công ty con của tập đoàn
                                    BBK Electronics.
                                    <br />
                                    Theo thống kê mới nhất cuối năm 2019, Vivo
                                    đã vượt mặt Samsung trở thành hãng điện
                                    thoại lớn nhất thị trường Ấn Độ. Tháng
                                    9/2019, Vivo đạt 5,4% thị phần tại Việt Nam.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">4. Oppo</div>
                                <img className="anhLoaiHoa" src={oppo} />
                                <div className="thongTinLoaiHoa">
                                    OPPO Electronics Corp là nhà sản xuất thiết
                                    bị điện tử, điện thoại di động Android Trung
                                    Quốc, có trụ sở đặt tại Đông Hoản, Quảng
                                    Đông, công ty con của tập đoàn điện tử BBK
                                    Electronics. <br /> Thành lập vào năm 2000,
                                    công ty đã đăng ký tên thương hiệu OPPO ở
                                    nhiều quốc gia trên thế giới. <br />
                                    OPPO hiện nằm trong top 5 hãng sản xuất điện
                                    thoại di động lớn nhất thế giới, đạt thị
                                    phần 8,1% vào đầu năm 2019. Tại Việt Nam,
                                    OPPO là hãng điện thoại di động đứng thứ 2,
                                    với thị phần lên tới 25,2% vào cuối năm
                                    2019.Sản phẩm chủ yếu của hãng là điện thoại
                                    thông minh trong phân khúc tầm trung và cận
                                    cao cấp, với dòng sản phẩm chiến lược OPPO
                                    Reno (trước đây là OPPO F series) cùng các
                                    dự án quảng cáo lớn, rầm rộ.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">5. Realme</div>
                                <img className="anhLoaiHoa" src={realme} />
                                <div className="thongTinLoaiHoa">
                                    Realme là công ty con của tập đoàn BBK
                                    Electronics, Realme cũng sản xuất nhiều loại
                                    sản phẩm khác như tai nghe, vòng đeo tay
                                    thông minh và túi xách. <br />
                                    Tháng 6 năm 2020, số lượng người dùng điện
                                    thoại thông minh trên toàn cầu của realme
                                    đạt 35 triệu và doanh số sản phẩm AIoT đã
                                    phá vỡ kỷ lục 1 triệu.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">6. Huawei</div>
                                <img className="anhLoaiHoa" src={huawei} />
                                <div className="thongTinLoaiHoa">
                                    Huawei là một tập đoàn công nghệ đến từ
                                    Trung Quốc, được thành lập vào năm 1987, có
                                    trụ sở chính đặt tại thành phố Thâm Quyến.{" "}
                                    <br />
                                    Trong thời kỳ đỉnh cao, Huawei đã từng là
                                    thương hiệu điện thoại có thị phần cao nhất
                                    trên toàn cầu và là một trong 5 nhà sản xuất
                                    tiên phong cho công nghệ 5G. <br />
                                    Hiện tại, Huawei chủ yếu tập trung kinh
                                    doanh các thiết bị điện tử ngoài điện thoại.
                                    Do một số vấn đề liên quan đến giấy phép bản
                                    quyền, các điện thoại của Huawei hiện không
                                    còn sử dụng hệ điều hành Android. Tuy vậy,
                                    nếu bạn ưa thích những trải nghiệm mới lạ và
                                    khác biệt thì điện thoại Huawei sẽ là một
                                    lựa chọn phù hợp với bạn.
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {loading === 1 && <Loading />}
                <div className="App2">
                    Ứng Dụng Được Tối Ưu Sử Dụng Trên Mobile
                </div>
            </div>
        </>
    );
}

export default App;
